import React, { useState, useEffect } from "react";
import axios from "axios";
import { Url } from './global';
import "./App.css"; // Include the external CSS for styling

function Quiz() {
  const [question, setQuestion] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [answer,setAnswer] = useState(null);
  const [clueIndex, setClueIndex] = useState(0);


  const doSomething = async() =>{
   const url = Url + "daily";
       const axiosOptions = {
           headers: {
             'Content-Type': 'application/json'
           }
       }

       const response = await axios.get(url, axiosOptions);
       setQuestion(response.data);
  }
  useEffect(() => {
    doSomething();
  }, []);

  const revealNextClue = () => {
    var index = clueIndex;
    setClueIndex(index+1);
  };

  const showAnswer = () => {
      setIsShow(true);
  };

  const hideAnswer = () => {
        setIsShow(false);
    };

  const answerVerify = () => {
    var answer = document.getElementById("answer").value;
    var actual = question.answer;
    if(answer != null && actual.toUpperCase() === answer.trim().toUpperCase()) {
      setAnswer(answer);
      setIsCorrect(true);
    } else if(answer != null && actual.toUpperCase() !== answer.toUpperCase()){
      setAnswer(answer);
      setIsCorrect(false);
    }
  };

  if (!question) return <div>Loading...</div>;

  return (
    <div className="page-container">
      <h1 className="title">Daily Chemistry Quiz</h1>
      <div className="question-container">
        <p className="question">{question.question}</p>
        <div className="clues-container">
          <button className="clue-button" onClick={revealNextClue}>Reveal a Clue</button>
          {clueIndex > 0 && <p style={{ marginTop: "10px" }}>Clue 1: {question.clue1}</p>}
          {clueIndex > 1 && <p style={{ marginTop: "10px" }}>Clue 2: {question.clue2}</p>}
          {clueIndex > 2 && <p style={{ marginTop: "10px" }}>Clue 3: {question.clue3}</p>}
        </div>
        <p style={{ marginTop: "10px" }}>Answer: <input type="text" id="answer"/></p>
        <button className="submit" onClick={answerVerify} onChange={answerVerify}> Go </button>
        {isCorrect && answer != null && <p style={{ marginTop: "10px",color:"#2F2" }}> Correct !! </p>}
        {!isCorrect && answer != null && <p style={{ marginTop: "10px",color:"#A22" }}> Take another guess </p>}
        <p style={{ marginTop: "10px",color:"#A22" }}> ---------------------------- </p>
        {!isShow && <button className="clue-button" onClick={showAnswer}>Show Answer</button>}
        {isShow && <button className="clue-button" onClick={hideAnswer}>Hide Answer</button>}
        {isShow && <p style={{ marginTop: "10px",color:"#2F2" }}> {question.answer} </p>}
      </div>
    </div>
  );
}

export default Quiz;
