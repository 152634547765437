import React from "react";

function About() {
  return (
    <div className="about-container">
      <h1>About Compound Guesser</h1>
      <p>
        Compound Guesser is an educational platform designed to make learning chemistry
        fun and interactive. Enjoy daily quizzes and discover fascinating facts
        about chemical compounds.
      </p>
    </div>
  );
}

export default About;
