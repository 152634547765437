import React from "react";

function Compounds() {
  return (
    <div className="left-aligned">
      <h1>Chemical Compounds</h1>
      <p>
        Chemical compounds are substances formed when two or more different types of atoms are bonded together in fixed proportions.
        These compounds have unique chemical and physical properties, distinguishing them from the individual elements that compose them.
      </p>

      <section>
        <h2>Types of Compounds</h2>
        <p>There are several types of compounds based on the nature of the bonds that hold their atoms together:</p>
        <ul>
          <li>
            <strong>Ionic Compounds:</strong>
            Formed by the transfer of electrons from one atom to another, creating positive and negative ions.
            These ions are held together by strong electrostatic forces. Example: Sodium chloride (NaCl).
          </li>
          <li>
            <strong>Covalent Compounds:</strong>
            Formed by the sharing of electrons between atoms. Covalent bonds are typically found in organic molecules.
            Example: Water (H<sub>2</sub>O).
          </li>
          <li>
            <strong>Metallic Compounds:</strong>
            Formed by the attraction between metal atoms and their collective pool of electrons.
            These compounds exhibit properties like electrical conductivity. Example: Bronze (an alloy of copper and tin).
          </li>
        </ul>
      </section>

      <section>
        <h2>Properties of Compounds</h2>
        <p>Compounds have distinct physical and chemical properties that depend on their molecular structure and bonding:</p>
        <ul>
          <li>
            <strong>Melting and Boiling Points:</strong> Ionic compounds usually have high melting and boiling points due to strong ionic bonds,
            while covalent compounds often have lower melting and boiling points.
          </li>
          <li>
            <strong>Solubility:</strong>
            Many ionic compounds dissolve in water (e.g., table salt), while some covalent compounds like oils are insoluble in water.
          </li>
          <li>
            <strong>Conductivity:</strong>
            Ionic compounds conduct electricity when dissolved in water, while covalent compounds typically do not.
          </li>
        </ul>
      </section>

      <section>
        <h2>Examples of Everyday Compounds</h2>
        <p>Chemical compounds are all around us and play critical roles in our daily lives. Here are some examples:</p>
        <ul>
          <li>
            <strong>Water (H<sub>2</sub>O):</strong>
            An essential compound for all known forms of life. It is a universal solvent and participates in numerous biological reactions.
          </li>
          <li>
            <strong>Carbon Dioxide (CO<sub>2</sub>):</strong>
            A compound exhaled by humans and used by plants during photosynthesis.
          </li>
          <li>
            <strong>Sodium Chloride (NaCl):</strong>
            Common table salt used for seasoning food and preserving it.
          </li>
          <li>
            <strong>Calcium Carbonate (CaCO<sub>3</sub>):</strong>
            Found in limestone and used in building materials like cement and chalk.
          </li>
        </ul>
      </section>

      <section>
        <h2>How Compounds are Named</h2>
        <p>The naming of compounds follows specific rules set by the International Union of Pure and Applied Chemistry (IUPAC):</p>
        <ul>
          <li>
            <strong>Ionic Compounds:</strong> The metal is named first, followed by the non-metal with an "-ide" suffix. Example: Sodium chloride.
          </li>
          <li>
            <strong>Covalent Compounds:</strong> Prefixes like mono-, di-, and tri- are used to indicate the number of each atom.
            Example: Carbon dioxide (CO<sub>2</sub>), Carbon monoxide (CO).
          </li>
        </ul>
      </section>

      <section>
        <h2>Interesting Facts</h2>
        <p>Here are some fascinating facts about compounds:</p>
        <ul>
          <li>
            The human body is made up of numerous compounds, including water, carbohydrates, proteins, and lipids.
          </li>
          <li>
            Diamond and graphite are both forms of carbon, but they have entirely different properties because of how their atoms are arranged.
          </li>
          <li>
            The compound table sugar (sucrose) has the formula C<sub>12</sub>H<sub>22</sub>O<sub>11</sub>.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Compounds;

