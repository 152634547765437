import React from "react";

function Basics() {
  return (
    <div className="left-aligned">
      <h1>Chemistry Basics</h1>
      <p>
        Chemistry is the study of matter, its properties, and the changes it undergoes.
        Understanding the basics of chemistry is essential for exploring the world around us.
      </p>

      <section>
        <h2>What is an Atom?</h2>
        <p>
          An atom is the basic unit of matter. Atoms are composed of three main subatomic particles:
        </p>
        <ul>
          <li>
            <strong>Protons:</strong> Positively charged particles located in the nucleus.
          </li>
          <li>
            <strong>Neutrons:</strong> Neutral particles also located in the nucleus.
          </li>
          <li>
            <strong>Electrons:</strong> Negatively charged particles orbiting the nucleus in electron shells.
          </li>
        </ul>
        <p>
          Atoms combine to form molecules, which are the building blocks of everything around us.
        </p>
      </section>

      <section>
        <h2>What is a Molecule?</h2>
        <p>
          A molecule is a group of two or more atoms bonded together. Molecules can consist of atoms
          of the same element or different elements. For example:
        </p>
        <ul>
          <li>
            <strong>O<sub>2</sub> (Oxygen):</strong> A molecule made up of two oxygen atoms.
          </li>
          <li>
            <strong>H<sub>2</sub>O (Water):</strong> A molecule made up of two hydrogen atoms and one oxygen atom.
          </li>
        </ul>
        <p>
          Molecules interact in chemical reactions, resulting in new substances with different properties.
        </p>
      </section>

      <section>
        <h2>States of Matter</h2>
        <p>
          Matter exists in different states, which are determined by the arrangement and movement of particles.
          The three most common states are:
        </p>
        <ul>
          <li>
            <strong>Solid:</strong> Particles are closely packed together in a fixed arrangement. Solids have
            a definite shape and volume. Example: Ice.
          </li>
          <li>
            <strong>Liquid:</strong> Particles are close together but can move past one another. Liquids
            have a definite volume but no fixed shape. Example: Water.
          </li>
          <li>
            <strong>Gas:</strong> Particles are far apart and move freely. Gases have no fixed shape or volume.
            Example: Oxygen.
          </li>
        </ul>
        <p>
          There are also other states of matter, such as plasma and Bose-Einstein condensates,
          which occur under extreme conditions.
        </p>
      </section>

      <section>
        <h2>Chemical Reactions</h2>
        <p>
          A chemical reaction occurs when substances interact to form new substances. During a reaction:
        </p>
        <ul>
          <li>Atoms are rearranged, but no new atoms are created or destroyed (law of conservation of mass).</li>
          <li>
            Reactants are the starting materials, and products are the substances formed as a result of the reaction.
          </li>
          <li>
            Example: The reaction between hydrogen (H<sub>2</sub>) and oxygen (O<sub>2</sub>) to form water (H<sub>2</sub>O):
            <br />
            <code>2H<sub>2</sub> + O<sub>2</sub> → 2H<sub>2</sub>O</code>
          </li>
        </ul>
        <p>
          Chemical reactions are vital to life, from photosynthesis in plants to the metabolism of food in humans.
        </p>
      </section>

      <section>
        <h2>Fun Fact</h2>
        <p>
          Did you know that the human body is made up of about 60% water, and water itself is composed of just two
          elements: hydrogen and oxygen? Chemistry helps us understand the importance of these elements in life processes!
        </p>
      </section>
    </div>
  );
}

export default Basics;

