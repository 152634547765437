import React from "react";

function PeriodicTable() {
  return (
    <div class="left-aligned">
      <h2>Periodic Table</h2>
      <p>Discover the elements of the periodic table and their properties.</p>
      <img src={require('./../assets/PeriodicTable.png')}  alt="Periodic Table" />
    </div>
  );
}

export default PeriodicTable;
